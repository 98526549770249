import Link from 'next/link'
import React, { useState } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { parseHtml } from '../../../utils/common'
import NextImage from 'next/image'
import handleViewport from 'react-in-viewport'

let baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL

function ServiceSection(props) {
	const strategies = props.strategies
	const { forwardedRef, inViewport } = props

	const [isHovering, setIsHovering] = useState(0)
	const [nohover, setNoHover] = useState(null)
	const [bgImage, setBgImage] = useState('')

	const handleMouseOver = (id, img) => {
		const imgUrl = img.data.attributes.url.replace('uploads', 'uploads/f_webp')
		setIsHovering(id)
		if (img && img.data) {
			setBgImage(baseUrl + imgUrl)
		} else {
			setBgImage('')
		}
		setNoHover('nohover')
	}

	const handleMouseOut = () => {
		setNoHover('')
		setBgImage('')
	}

	return (
		<>
			<section ref={forwardedRef} style={{minHeight: '1400px'}} className="service-section">
				{inViewport && (
					<div className="service-inner">
						<Container>
							<Row className="justify-content-center">
								<Col xl={12} lg={12} md={12}>
									<div className="heading">
										<h3>{strategies?.Subtitle}</h3>
										<h2>{strategies?.Title}</h2>
									</div>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col lg={12}>
									<div
										className="service-bg-image block-img"
										style={{ backgroundImage: `url(${bgImage})` }}
									>
										<Row className=" justify-content-center">
											<Col lg={11}>
												<Row className="list-fetaure">
													{strategies?.Card.map((card, index) => (
														<Col lg={6} md={6} sm={12} key={index}>
															<Link
																href={card?.button?.href || '#'}
																id={card.id}
																className={`service-feature ${isHovering === card.id ? 'hover' : nohover
																	}`}
																onMouseEnter={() =>
																	handleMouseOver(card.id, card.image)
																}
																onMouseLeave={() => handleMouseOut()}
															>
																<div className="service-feature-inner">
																	<h3>
																		{card.title}{' '}
																		<Image
																			width={14}
																			height={14}
																			alt="arrow"
																			src="../images/cross-right-arrow.svg"
																		/>
																	</h3>
																	{card.text ? parseHtml(card.text) : ''}
																	<ul className="service-tools">
																		{card.tags?.map((tag, index) => (
																			<li key={index}>{tag.Title}</li>
																		))}
																	</ul>
																</div>
															</Link>
														</Col>
													))}
												</Row>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				)}
			</section>
		</>
	)
}

const ServiceSectionViewportBlock = handleViewport(ServiceSection);

export default ServiceSectionViewportBlock
